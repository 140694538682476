<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 15"
  >
    <path
      fill="#191617"
      d="M8.5,8.5h-7c-1.3,0-2,1.6-1.1,2.6l3.5,3.5c0.6,0.6,1.5,0.6,2.1,0l3.5-3.5C10.5,10.1,9.8,8.5,8.5,8.5z M5,13.5 L1.5,10h7L5,13.5z M1.5,6.5h7c1.3,0,2-1.6,1.1-2.6L6.1,0.4c-0.6-0.6-1.5-0.6-2.1,0L0.5,3.9C-0.5,4.9,0.2,6.5,1.5,6.5z M5,1.5L8.5,5 h-7L5,1.5z"
    />
  </svg>
</template>
